import * as React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"

const LandingPage = (props) => {
    const pages = props.data.allContentfulLandingPages.edges

    const groupedByCategory = pages.reduce((acc, { node }) => {
        const { category, slug, section1Title, scope } = node;
        if (!acc[category]) acc[category] = [];
        acc[category].push({ slug, section1Title, scope });
        return acc;
    }, {});

    return (
        <Layout>
            <Seo
                title="Explore Our Services: Expert Landing Pages Tailored For Your Needs"
            />
            <div className=''>
                <div className="container mx-auto py-7 lg:py-8 px-10 pb-9 lg:pb-12">
                    <div className="pt-5 text-center lg:text-left mx-auto">
                        <h1 className="font-bold text-3xl md:text-4xl py-5 mx-auto text-center">
                            L&D Solutions Service Landing Pages
                        </h1>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-10 lg:pt-20">
                        {Object.entries(groupedByCategory).map(([category, items]) => (
                            <div key={category} className="category-group">
                                <h2 className="text-2xl font-bold mb-4">{category}</h2>
                                <ul className="list-none pl-0">
                                    {items.map((item) => (
                                        <li key={item.slug} className="mb-2">
                                            <Link to={item.slug} className="hover:text-lndred flex gap-x-1">
                                                <Bullet />{item.section1Title} - {item.scope ? 'Local' : 'National'}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </Layout>
    )
}
export default LandingPage

function Bullet() {
    return (
        <svg className='mt-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8" height="8">
            <circle cx="12" cy="12" r="8" fill="currentColor" />
        </svg>)
}

export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulLandingPages {
        edges {
            node {
                slug
                scope
                category
                section1Title
            }
        }
    }
}
`